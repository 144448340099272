
import { Options, mixins } from "vue-class-component";
import { Forms } from "@/mixins";
import { copy } from "@/utils";
import { useToast } from "balm-ui";
import { minLength, required } from "@vuelidate/validators";
import { onlyAlpha } from "@/validators";
import { LanguagesInterface } from "@/types";
import { languagesModule } from "@/store";
import AlertsError from "@/components/forms/alerts/error/index.vue";

const $toast = useToast();

@Options({
  name: "LanguagesForm",
  components: { AlertsError },
  validations: {
    formData: {
      name: {
        required,
        onlyAlpha,
        minLength: minLength(2),
      },
      code: {
        required,
        onlyAlpha,
        minLength: minLength(2),
      },
    },
  },
})
export default class LanguagesForm extends mixins(Forms) {
  private itemId!: string;
  private form: LanguagesInterface = {
    name: "",
    code: "",
  };

  private get formData(): LanguagesInterface {
    return this.form;
  }

  private set formData(data: LanguagesInterface) {
    this.form = data;
  }

  private addLanguage(): void {
    this.roastMessage = "Added!";

    languagesModule.Add({
      language: this.formData,
      callback: () => {
        $toast({ timeoutMs: this.toastTimeOut, message: this.roastMessage });

        setTimeout(() => {
          this.isSaved = false;
          this.$router.push({ name: "Languages" });
        }, this.toastTimeOut);
      },
    });
  }

  private editLanguage(): void {
    this.roastMessage = "Saved!";

    languagesModule.Edit({
      id: this.itemId,
      payload: this.formData,
      callback: () => {
        $toast({ timeoutMs: this.toastTimeOut, message: this.roastMessage });

        setTimeout(() => {
          this.isSaved = false;
          this.$router.push({ name: "Languages" });
        }, this.toastTimeOut);
      },
    });
  }

  private onSave(): void {
    this.isSaved = true;
    this.isEditSubRoute ? this.editLanguage() : this.addLanguage();
  }

  created(): void {
    const params = copy(this.$route.params);

    if (this.isEditSubRoute && !Object.keys(params).length) {
      this.$router.go(-1);
    } else {
      this.itemId = params.id;
      this.formData = { ...this.formData, ...params };
    }
  }
}
