
import { Options, mixins } from "vue-class-component";
import { Forms } from "@/mixins";
import { copy } from "@/utils";
import { useToast } from "balm-ui";
import { minLength, required } from "@vuelidate/validators";
import { LanguagesInterface, MenuInterface } from "@/types";
import { languagesModule, menuModule } from "@/store";
import AlertsError from "@/components/forms/alerts/error/index.vue";

const $toast = useToast();

@Options({
  name: "MenuForm",
  components: { AlertsError },
  watch: {
    language(val) {
      if (val) languagesModule.setActiveLanguage(val);
    },
  },
  validations: {
    formData: {
      title: {
        required,
        minLength: minLength(2),
      },
      name: {
        required,
        minLength: minLength(2),
      },
      link: {
        required,
      },
    },
  },
})
export default class MenuForm extends mixins(Forms) {
  private itemId!: string;
  private language = "";
  private form: MenuInterface = {
    title: "",
    name: "",
    link: "",
  };

  private get formData(): MenuInterface {
    return this.form;
  }

  private set formData(data: MenuInterface) {
    this.form = data;
  }

  private get getLanguages(): LanguagesInterface[] {
    return languagesModule.getLanguages;
  }

  private selectData(data: any): Array<{ [key: string]: string }> {
    if (!data) return [];
    let selectData: { [key: string]: any }[] = [];

    for (const asset of data) {
      selectData.push({
        label: asset.title || asset.name,
        value: asset.id,
      });
    }

    return selectData;
  }

  private addMenu(): void {
    this.roastMessage = "Added!";

    menuModule.Add({
      menu: this.formData,
      callback: (error?: any) => {
        if (error) {
          $toast({ timeoutMs: this.toastTimeOut, message: error });
          return;
        }

        $toast({ timeoutMs: this.toastTimeOut, message: this.roastMessage });

        setTimeout(() => {
          this.isSaved = false;
          this.$router.push({ name: "Menu" });
        }, this.toastTimeOut);
      },
    });
  }

  private EditMenu(): void {
    this.roastMessage = "Saved!";

    menuModule.Edit({
      id: this.itemId,
      payload: this.formData,
      callback: (error?: any) => {
        if (error) {
          $toast({ timeoutMs: this.toastTimeOut, message: error });
          return;
        }

        $toast({ timeoutMs: this.toastTimeOut, message: this.roastMessage });

        setTimeout(() => {
          this.isSaved = false;
          this.$router.push({ name: "Menu" });
        }, this.toastTimeOut);
      },
    });
  }

  private onSave(): void {
    this.isSaved = true;
    this.isEditSubRoute ? this.EditMenu() : this.addMenu();
  }

  created(): void {
    const params = copy(this.$route.params);

    if (this.isEditSubRoute && !Object.keys(params).length) {
      this.$router.push({name: "Menu/List"});
    } else {
      this.itemId = params.id;
      this.formData = { ...this.formData, ...params };
    }

    languagesModule.$watch(
        (module) => module.getActiveLanguage,
        () => menuModule.Load()
    );

    menuModule.$watch(
        (module) => module.getMenu,
        () => {
          const [menu] = menuModule.getMenu.filter(
              (m: MenuInterface) => m.id === this.itemId
          );

          if (menu) {
            this.formData = { ...this.formData, ...menu };
          }
        }
    );
  }

  beforeUnmount(): void {
    languagesModule.setActiveLanguage("");
  }
}
